import React, { Fragment, useState } from "react";
import {
  Button,
  Col,
  Form,
  Row,
  Container,
  Card,
  Spinner,
} from "react-bootstrap";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { object, string } from "yup";
import { login, setLoggedIn } from "../store/slice/auth";
import { useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import * as Yup from "yup";
import AuthenticationService from "../services/auth.service";
const SignIn = () => {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setLoading] = useState(false);
  const formerr = {
    color: "#ff3300",
    fontSize: "16px",
    fontWeight: "500px",
  };
  const schema = object({
    password: Yup.string()
      // .min(6, 'Password must be 6 character length')
      .required("Password is required!"),
    email: Yup.string().required("Email is required!"),
  });
  const initialValue = {
    email: "",
    password: "",
  };
  const handleFormSubmit = async (values, action) => {
    const { email, password } = values;
    setLoading(true);
    try {
      await dispatch(login({ email, password })).unwrap();
      // const response = await AuthenticationService.signUp({ email, password });
      // console.log("response", response);
      dispatch(setLoggedIn(true));
      sessionStorage.setItem("isLoggedIn", true);
      sessionStorage.setItem("email", email);
      navigate("/users/");
      action.resetForm();
      enqueueSnackbar("You have successfully logged in.", {
        variant: "success",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
      // window.location.reload();
    } catch (e) {
      action.resetForm();
      navigate("/login");
      console.log("e", e);
      enqueueSnackbar(e.response.data.apierror.message, {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
    } finally {
      setLoading(false);
    }
  };
  const formik = useFormik({
    initialValues: initialValue,
    validationSchema: schema,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: handleFormSubmit,
  });
  return (
    <>
      <Fragment>
        <div className="page main-signin-wrapper">
          <Row className="signpages text-center">
            <Col md={12}>
              <Card>
                <Row className="row-sm">
                  <Col
                    lg={6}
                    xl={5}
                    className="d-none d-lg-block text-center bg-primary details"
                  >
                    <div className="p-2 pos-absolute">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          marginBottom: "15px",
                        }}
                      >
                        <h3 style={{ color: "#FFFCFD", marginRight: "5px" }}>
                          GENERATE
                        </h3>{" "}
                        <h3 style={{ color: "#FFFCFD" }}>SUBTITLE</h3>
                      </div>
                      <div className="clearfix"></div>
                      <img
                        src={
                          require("../../assets/img/svgs/logoGs.svg").default
                        }
                        className="ht-100 mb-0"
                        alt="logo"
                      />
                      <h5 className="mt-4 text-white">Welcome Back!</h5>
                      <span className="tx-white-6 tx-13 mb-5 mt-xl-0">
                        Login to continue!
                      </span>
                    </div>
                  </Col>
                  <Col lg={6} xl={7} xs={12} sm={12} className="login_form ">
                    <Container fluid>
                      <Row className="row-sm">
                        <Card.Body className="mt-2 mb-2 text-center">
                          <form onSubmit={formik.handleSubmit} noValidate>
                            <h5 className="mb-2">Signin to Your Account</h5>
                            <Form.Group
                              className="text-start form-group"
                              controlId="formEmail"
                            >
                              <Form.Label>Email</Form.Label>
                              <Form.Control
                                className="form-control"
                                placeholder="Enter your email"
                                type="email"
                                name="email"
                                onChange={formik.handleChange}
                                value={formik.values.email}
                              />
                              {formik.errors.email && formik.touched.email ? (
                                <p style={formerr}>{formik.errors.email}</p>
                              ) : null}
                            </Form.Group>
                            <Form.Group
                              className="text-start form-group"
                              controlId="formpassword"
                            >
                              <Form.Label>Password</Form.Label>
                              <Form.Control
                                className="form-control"
                                placeholder="Enter your password"
                                name="password"
                                type="password"
                                value={formik.values.password}
                                onChange={formik.handleChange}
                              />
                              {formik.errors.password &&
                              formik.touched.password ? (
                                <p style={formerr}>{formik.errors.password}</p>
                              ) : null}
                            </Form.Group>
                            <Button
                              type="submit"
                              className="btn ripple btn-main-primary btn-block mt-2"
                            >
                              {isLoading ? (
                                <Spinner
                                  animation="border"
                                  role="status"
                                  size="sm"
                                />
                              ) : (
                                "Sign In"
                              )}
                            </Button>
                          </form>
                        </Card.Body>
                      </Row>
                    </Container>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </div>
      </Fragment>
    </>
  );
};
SignIn.propTypes = {};
SignIn.defaultProps = {};
export default SignIn;
