import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Button, Form, Modal, Spinner } from "react-bootstrap";
import { faEye, faEyeSlash } from "@fortawesome/free-regular-svg-icons";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import moment from "moment";

const EditUserModal = (props) => {
  const { formik, isUpdate, showPassword, handleTogglePwd, isLoading } = props;
  const [authorities, setAuthorities] = useState([]);

  if (!formik.values.authorities) {
    formik.values.authorities = [];
  }

  const autoritiesOptions = [
    "Speech to text",
    "Video insights",
    "Fix Hidden Characters",
  ];

  return (
    <Modal
      {...props}
      size="large"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title>{isUpdate ? "Update User" : "Add User"}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-center" style={{ paddingBottom: "initial" }}>
        <div className="col-122">
          <div className="card custom-card">
            <form onSubmit={formik.handleSubmit}>
              <div
                className="col-12"
                style={{
                  overflow: "auto",
                  marginBottom: "15px",
                  maxHeight: "650px",
                }}
              >
                <Form.Group
                  controlId="validationFormik101"
                  className="position-relative"
                >
                  <Form.Label
                    style={{
                      textAlign: "start",
                      color: "#000",
                      marginTop: "15px",
                    }}
                  >
                    Email<span className="tx-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="email"
                    name="email"
                    placeholder="Enter your email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                  />
                  {formik.errors.email && formik.touched.email ? (
                    <p className="text-start error">{formik.errors.email}</p>
                  ) : null}
                </Form.Group>
                {/* {!isUpdate && */}

                <Form.Group
                  controlId="validationFormik101"
                  className="position-relative"
                >
                  <Form.Label
                    style={{
                      textAlign: "start",
                      color: "#000",
                      marginTop: "15px",
                    }}
                  >
                    Company Name
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="compnyName"
                    placeholder="Enter your company name"
                    value={formik.values.compnyName}
                    onChange={formik.handleChange}
                  />
                  {formik.errors.compnyName && formik.touched.compnyName ? (
                    <p className="text-start error">
                      {formik.errors.compnyName}
                    </p>
                  ) : null}
                </Form.Group>

                <Form.Group controlId="userRole">
                  <Form.Label
                    style={{
                      color: "#000",
                      textAlign: "start",
                      marginTop: "15px",
                    }}
                  >
                    Role
                  </Form.Label>
                  <Form.Control
                    as="select"
                    name="userRole"
                    value={formik.values.userRole}
                    onChange={formik.handleChange}
                  >
                    <option value="USER">User</option>
                    <option value="ADMIN">Admin</option>
                  </Form.Control>
                </Form.Group>

                <Form.Group
                  controlId="validationFormik101"
                  className="position-relative"
                >
                  <Form.Label
                    style={{
                      textAlign: "start",
                      color: "#000",
                      marginTop: "15px",
                    }}
                  >
                    Contact Person
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="contactPerson"
                    placeholder="Enter your contact person "
                    value={formik.values.contactPerson}
                    onChange={formik.handleChange}
                  />
                  {formik.errors.contactPerson &&
                  formik.touched.contactPerson ? (
                    <p className="text-start error">
                      {formik.errors.contactPerson}
                    </p>
                  ) : null}
                </Form.Group>
                <Form.Group
                  controlId="validationFormik101"
                  className="position-relative"
                >
                  <Form.Label
                    style={{
                      textAlign: "start",
                      color: "#000",
                      marginTop: "15px",
                    }}
                  >
                    Expired Date
                  </Form.Label>
                  <Form.Control
                    type="date"
                    name="expiredDate"
                    placeholder="Price"
                    value={moment(formik.values.expiredDate).format(
                      "YYYY-MM-DD"
                    )}
                    onChange={(e) => {
                      // Get the selected date from the input
                      const selectedDate = e.target.value;

                      // Convert the selected date to a timestamp
                      const timestamp = new Date(selectedDate).getTime();

                      // Update the expiredDate field with the timestamp
                      formik.setFieldValue("expiredDate", timestamp);
                    }}
                  />
                </Form.Group>
                <Form.Group controlId="authorities">
                  <Form.Label
                    style={{
                      color: "#000",
                      textAlign: "start",
                      marginTop: "15px",
                    }}
                  >
                    Authorities
                  </Form.Label>
                  <div style={{ textAlign: "left", padding: "10px" }}>
                    {autoritiesOptions?.map((option, i) => {
                      return (
                        <>
                          <Form.Check
                            type="checkbox"
                            id={i}
                            label={option}
                            value={option}
                            checked={formik.values.authorities?.includes(
                              option
                            )}
                            onChange={(e) => {
                              const isChecked = e.target.checked;
                              const value = e.target.value;
                              const authorities = formik.values.authorities;
                              if (isChecked) {
                                formik.setFieldValue("authorities", [
                                  ...authorities,
                                  value,
                                ]);
                              } else {
                                formik.setFieldValue(
                                  "authorities",
                                  authorities.filter((item) => item !== value)
                                );
                              }
                            }}
                          />
                        </>
                      );
                    })}
                  </div>
                </Form.Group>

                <Form.Group
                  controlid="validationFormik101"
                  //   className="position-relative"
                >
                  <Form.Label
                    style={{
                      color: "#000",
                      textAlign: "start",
                    }}
                  >
                    Phone Number
                  </Form.Label>
                  <PhoneInput
                    // className="addContacts"
                    country={"in"}
                    // value={"in"}
                    value={
                      formik.values?.phoneNumber &&
                      formik.values?.countryCode &&
                      formik.values?.countryCode + formik.values?.phoneNumber
                    }
                    onChange={(value, country) => {
                      formik.setFieldValue(
                        "phoneNumber",
                        value.split(country?.dialCode)[1]
                      );
                      formik.setFieldValue("countryCode", country?.dialCode);
                    }}
                    // disabled={!isEdit}
                  />
                </Form.Group>

                <Form.Group
                  controlId="validationFormik101"
                  className="position-relative"
                >
                  <Form.Label
                    style={{
                      textAlign: "start",
                      color: "#000",
                      marginTop: "15px",
                    }}
                  >
                    Password<span className="tx-danger">*</span>
                  </Form.Label>
                  <div>
                    <Form.Control
                      type={showPassword ? "text" : "password"}
                      name="password"
                      placeholder="Enter your password"
                      className="pos-relative"
                      value={formik.values.password}
                      onChange={formik.handleChange}
                    />
                    {formik.errors.password && formik.touched.password ? (
                      <p className="text-start error">
                        {formik.errors.password}
                      </p>
                    ) : null}
                    <FontAwesomeIcon
                      icon={showPassword ? faEye : faEyeSlash}
                      onClick={handleTogglePwd}
                      style={{
                        cursor: "pointer",
                        position: "absolute",
                        right: 20,
                        top: "38px",
                      }}
                    />
                  </div>
                </Form.Group>
                {/* } */}
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Button
                  type="submit"
                  style={{
                    display: "flex",
                    marginLeft: "auto",
                    marginRight: "15px",
                    textAlign: "center",
                  }}
                >
                  {isLoading ? (
                    <Spinner animation="border" role="status" size="sm" />
                  ) : isUpdate ? (
                    "Update"
                  ) : (
                    "Submit"
                  )}
                </Button>
              </div>
            </form>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default EditUserModal;
