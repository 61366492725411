import React, { useEffect, useState } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import LoginImage from "../../../assets/img/login-background.png";
import ImageService from "../../services/image.service";
import { enqueueSnackbar } from "notistack";

const Home = () => {
  const [selectedImage, setSelectedImage] = useState(LoginImage);
  const [apiImage, setApiImage] = useState("");

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file && (file.type === "image/jpeg" || file.type === "image/png")) {
      setApiImage(file);
      const reader = new FileReader();
      reader.onload = (e) => {
        setSelectedImage(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const getImage = async () => {
    try {
      const response = await ImageService.getImage();
      setSelectedImage(response.data.apiresponse.data.image);
    } catch (error) {
      enqueueSnackbar(error.response.data.apiresponse.message, {
        variant: "error",
        anchorOrigin: {
          horizontal: "right",
          vertical: "bottom",
        },
      });
    }
  };

  useEffect(() => {
    getImage();
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      if (apiImage !== "") {
        const formData = new FormData();
        formData.append("image", apiImage);
        const response = await ImageService.postImage(formData);
        enqueueSnackbar("Image Upload Successfully", {
          variant: "success",
          anchorOrigin: {
            horizontal: "right",
            vertical: "bottom",
          },
        });
      } else {
        enqueueSnackbar("Please Upload Image", {
          variant: "error",
          anchorOrigin: {
            horizontal: "right",
            vertical: "bottom",
          },
        });
      }
    } catch (error) {
      enqueueSnackbar(error.response.data.apiresponse.message, {
        variant: "error",
        anchorOrigin: {
          horizontal: "right",
          vertical: "bottom",
        },
      });
    }
  };

  return (
    <div>
      <div className="page-header">
        <div>
          <h2 className="main-content-title tx-24 mg-b-5">Home</h2>
        </div>
      </div>
      <div className="w-100">
        <Row>
          <Col lg={6} sm={12}>
            <Card className="custom-card overflow-hidden">
              <Card.Body>
                <form>
                  <div className="responsive">
                    <Card className="custom-card overflow-hidden mb-0 rounded-0">
                      <div>
                        <div className="gap-4 d-flex flex-column">
                          <div class="mb-3">
                            <label for="formFile" class="form-label">
                              Select Image
                            </label>
                            <input
                              class="form-control"
                              type="file"
                              id="formFile"
                              accept=".jpg,.jpeg,.png"
                              onChange={handleImageChange}
                            />
                          </div>
                          <div
                            style={{
                              height: "100%",
                              maxHeight: "350px",
                              overflow: "hidden",
                            }}
                          >
                            <img
                              src={selectedImage}
                              alt="Selected"
                              style={{
                                maxWidth: "100%",
                                height: "auto",
                                objectFit: "cover",
                                backgroundPosition: "center",
                              }}
                            />
                          </div>
                          <div className="d-flex">
                            <div className="">
                              <Button
                                variant="primary"
                                type="button"
                                onClick={handleSubmit}
                                className="btn-icon-text"
                              >
                                Submit
                              </Button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Card>
                  </div>
                </form>
              </Card.Body>
            </Card>
          </Col>
          <Col></Col>
        </Row>
      </div>
    </div>
  );
};

export default Home;
