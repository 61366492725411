import { useState } from 'react'

function useDialogState() {
   const [open, setOpen] = useState(false)
   const [openDelete, setOpenDelete] = useState(false)
   const [openReset, setOpenReset] = useState(false)

   const handleClickOpen = () => {
      setOpen(true)
   }
   const handleClickOpenDelete = () => {
      setOpenDelete(true)
   }
   const handleClickOpenReset = () => {
      setOpenReset(true)
   }
   const handleClickClose = () => {
      setOpen(false)
   }
   const handleClickCloseDelete = () => {
      setOpenDelete(false)
   }
   const handleClickCloseReset = () => {
      setOpenReset(false)
   }

   return { open, openDelete, openReset, handleClickOpenDelete, handleClickOpenReset, handleClickOpen, handleClickCloseDelete, handleClickCloseReset, handleClickClose }
}

export default useDialogState
