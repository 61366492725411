import React from "react";
import { Navigate, useRoutes } from "react-router-dom";
import AuthLogin from "./app/authentication/login";
import MatxLayout from "./app/component/maxLayout";
import NotFound from "./app/layout/sessions/NotFound";
import UsersList from "./app/component/users/UsersList";
import AboutUs from "./app/component/aboutUs/AboutUs";
import ContactUs from "./app/component/contactUs/ContactUs";
import UserEdit from "./app/component/users/UserEdit";
import CreateUser from "./app/component/users/CreateUser";
import Home from "./app/component/home/Home";
import SpeechToText from "./app/component/speech-to-text/SpeechToText";
import VideoInsights from "./app/component/video-insight/VideoInsights";
import FixHiddenCharacters from "./app/component/fix-hidden-characters/FixHiddenCharacters";

function RedirectionWrapper({ to }) {
  const queryString = window.location.search;
  if (queryString) {
    return <Navigate to={`${to}${queryString}`} />;
  }
  return <Navigate to={to} />;
}

const routes = (isLoggedIn) => [
  {
    path: "/",
    element: isLoggedIn ? <MatxLayout /> : <RedirectionWrapper to="/login" />,
    children: [
      {
        index: true,
        element: <RedirectionWrapper to="/" />,
      },
      {
        path: "/users/",
        element: <UsersList />,
      },
      {
        path: "/users/create",
        element: <CreateUser />,
      },
      {
        path: "/users/edit/:id",
        element: <UserEdit />,
      },
      {
        path: "/aboutus/",
        element: <AboutUs />,
      },
      {
        path: "/contactus/",
        element: <ContactUs />,
      },
      {
        path: "/home/",
        element: <Home />,
      },
      {
        path: "/speech-to-text/",
        element: <SpeechToText />,
      },
      {
        path: "/video-insight/",
        element: <VideoInsights />,
      },
      {
        path: "/fix-hidden-characters/",
        element: <FixHiddenCharacters />,
      },
    ],
  },
  {
    path: "/login",
    element: !isLoggedIn ? <AuthLogin /> : <RedirectionWrapper to="/users/" />,
  },
  { path: "*", element: <NotFound /> },
];

export default function Routes(props) {
  const { isLoggedIn } = props;
  return useRoutes(routes(isLoggedIn));
}
