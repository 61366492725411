import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react'
import { Button, Form, Modal } from 'react-bootstrap';
import { faEye, faEyeSlash } from '@fortawesome/free-regular-svg-icons';

const ResetPassword = (props) => {
    const { formik, isUpdate, showPassword, handleTogglePwd } = props

    return (
        <Modal
            {...props}

            size="large"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
        >
            <Modal.Header closeButton>
                <Modal.Title>Reset Password</Modal.Title>
            </Modal.Header>
            <Modal.Body className="text-center" style={{ paddingBottom: 'initial' }} >
                <div className="col-122">
                    <div className="card custom-card">
                        <form
                        // onSubmit={formik.handleSubmit}
                        >
                            <div className="col-12" style={{
                                overflow: "auto",
                                marginBottom: "15px",
                                maxHeight: "650px",
                            }} >
                                <Form.Group
                                    controlId="validationFormik101"
                                    className="position-relative"
                                >
                                    <Form.Label
                                        style={{
                                            textAlign: "start",
                                            color: "#000",
                                        }}
                                    >
                                        Password<span className="tx-danger">*</span>
                                    </Form.Label>
                                    <div>
                                        <Form.Control
                                            type={showPassword ? 'text' : 'password'}
                                            name="password"
                                            placeholder="Enter your password"
                                            className='pos-relative'
                                        />
                                        <FontAwesomeIcon icon={showPassword ? faEye : faEyeSlash} onClick={handleTogglePwd} style={{ cursor: 'pointer', position: 'absolute', right: 20, top: "38px" }} />
                                    </div>
                                </Form.Group>
                            </div>
                            <div style={{ display: "flex", justifyContent: "center", alignItems: 'center' }}>
                                <Button
                                    type="submit"
                                    style={{
                                        display: "flex",
                                        marginLeft: "auto",
                                        marginRight: "15px",
                                        textAlign: 'center',
                                    }}
                                >
                                    {isUpdate ?
                                        "Update"
                                        : 'Submit'}
                                </Button>
                            </div>
                        </form>
                    </div>
                </div>
            </Modal.Body>

        </Modal>
    );
}

export default ResetPassword
