import React, { useEffect, useState } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { enqueueSnackbar } from "notistack";
import InfoService from "../../services/info.service";

const SpeechToText = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [aboutData, setAboutData] = useState({ text: "" });

  const fetchAboutData = async () => {
    try {
      const response = await InfoService.getAbout();
      setAboutData(response?.data?.apiresponse?.data);
    } catch (e) {
      console.log("e", e);
      enqueueSnackbar(
        e.response?.data?.apierror?.message || "An error occurred",
        {
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        }
      );
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchAboutData();
  }, []);

  const formik = useFormik({
    initialValues: {
      aboutText: aboutData?.text || "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      aboutText: Yup.string().required("Speech to Text text is required"),
    }),
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: async (values, { setSubmitting }) => {
      console.log(values, "values");
      const data = {
        text: values?.aboutText,
      };

      try {
        const response = await InfoService.createAbout(data);
        console.log("create", response);
        fetchAboutData();
        enqueueSnackbar("Speech to Text details saved successfully.", {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });
      } catch (e) {
        console.log("e", e);
        enqueueSnackbar(
          e.response?.data?.apierror?.message || "An error occurred",
          {
            variant: "error",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right",
            },
          }
        );
      } finally {
        setSubmitting(false);
      }
    },
  });

  console.log(aboutData, "aboutData");

  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          fontSize: "1.5rem",
        }}
      >
        Loading...
      </div>
    );
  }

  return (
    <div>
      <div className="page-header">
        <div>
          <h2 className="main-content-title tx-24 mg-b-5">Speech to Text</h2>
        </div>
      </div>
      <div className="w-100">
        <Row>
          <Col lg={6} sm={12}>
            <Card className="custom-card overflow-hidden">
              <Card.Body>
                <form onSubmit={formik.handleSubmit}>
                  <div className="responsive">
                    <Card className="custom-card overflow-hidden">
                      <div>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            flexFlow: "wrap",
                            gap: "10px",
                            marginBottom: "14px",
                            marginTop: "4px",
                          }}
                        >
                          <textarea
                            className="p-2"
                            name="aboutText"
                            placeholder="Enter text here..."
                            style={{
                              border: "1px solid #ced4da",
                              borderRadius: "4px",
                              width: "100%",
                              height: "150px",
                            }}
                            value={formik.values.aboutText}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                          {formik.touched.aboutText &&
                          formik.errors.aboutText ? (
                            <div className="error-message">
                              {formik.errors.aboutText}
                            </div>
                          ) : null}
                          <div className="d-flex">
                            <div className="justify-content-center">
                              <Button
                                variant="primary"
                                type="submit"
                                className="btn-icon-text"
                                disabled={formik.isSubmitting}
                              >
                                {formik.isSubmitting ? "Submitting" : "Submit"}
                              </Button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Card>
                  </div>
                </form>
              </Card.Body>
            </Card>
          </Col>
          <Col></Col>
        </Row>
      </div>
    </div>
  );
};

export default SpeechToText;
