import React, { useEffect, useRef } from "react";
import withLoader from "../../layout/loader/withLoader";
import DataTable from "react-data-table-component";
import { Button, Card } from "react-bootstrap";
import { useState } from "react";
import Loader1 from "../../../assets/img/svgs/loader.svg";
import UserService from "../../services/user.service";
import EditUserModal from "./EditUserModal";
import useDialogState from "../../hooks/useDialogState";
import DeleteModal from "./DeleteModal";
import ResetUserModal from "./ResetPasswordl";
import moment from "moment/moment";
import { object } from "yup";
import * as Yup from "yup";
import { useFormik } from "formik";
import { enqueueSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";

const UsersList = () => {
  let navigate = useNavigate();
  const [totalRows, setTotalRows] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [userData, setUsersData] = useState("");
  const [searchText, setSearchText] = useState("");
  const [dataPerPage, setDataPerPage] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [showPassword, setShowPassword] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [userEmail, setUserEmail] = useState("");

  const {
    open,
    openDelete,
    openReset,
    handleClickOpen,
    handleClickOpenDelete,
    handleClickOpenReset,
    handleClickClose,
    handleClickCloseDelete,
    handleClickCloseReset,
  } = useDialogState();

  const initialValues = {
    id: "",
    email: "",
    password: "",
    expiredDate: "",
    compnyName: "",
    contactPerson: "",
    phoneNumber: "",
    countryCode: "",
    authorities: [],
  };
  const validationSchema = object({
    password: isUpdate ? "" : Yup.string().required("Password is required!"),
    email: Yup.string().required("Email is required!"),
  });
  const handleFormSubmit = async (values, action) => {
    setIsLoading(true);
    const { authorities, email, password } = values;
    const newAuthorities = authorities?.filter((item) => item.trim() !== "");
    console.log(values, "valuesvalues");
    if (!values.id) {
      try {
        const response = await UserService.createUser({
          ...values,
          authorities: newAuthorities,
        });
        console.log("create", response);
        action.resetForm();
        handleClickClose();
        AllUsers();
        enqueueSnackbar("Create user successfully", {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });
      } catch (e) {
        console.log("e", e);
        enqueueSnackbar(e.response.data.apierror.message, {
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });
      } finally {
        setIsLoading(false);
      }
    } else {
      try {
        const response = await UserService.updateUser(values.id, {
          ...values,
          authorities: newAuthorities,
        });
        action.resetForm();
        handleClickClose();
        AllUsers();
        enqueueSnackbar("Update user successfully", {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });
      } catch (e) {
        console.log("e", e);
      } finally {
        setIsLoading(false);
      }
    }
  };
  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: handleFormSubmit,
  });
  const AllUsers = async () => {
    setIsLoading(true);
    try {
      const response = await UserService.GetAllUsers({
        pageNumber: pageNumber,
        pageSize: dataPerPage,
        searchText: searchText,
      });
      console.log("responseresponse", response);
      const result = response.data.apiresponse.data.data;
      setTotalRows(response.data.apiresponse.data.totalCount);
      setUsersData(result);
    } catch (e) {
      console.log("e", e);
    } finally {
      setIsLoading(false);
    }
  };
  const userDelete = async () => {
    setIsLoading(true);
    try {
      const response = await UserService.deleteUser(deleteId);
      handleClickCloseDelete();
      AllUsers();
      enqueueSnackbar("User deleted successfully.", {
        variant: "success",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
    } catch (error) {
      enqueueSnackbar("An error occurred while deleting the user", {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    AllUsers(pageNumber, dataPerPage, searchText);
  }, [pageNumber, dataPerPage, searchText]);

  const DEBOUNCE_THRESHOLD = 500;
  const timeoutHandler = useRef(null);
  const debounceSearch = (event) => {
    if (timeoutHandler.current) {
      clearTimeout(timeoutHandler.current);
    }
    // setIsLoading(true)
    timeoutHandler.current = setTimeout(() => {
      setSearchText(event);
      // setIsLoading(false)
    }, DEBOUNCE_THRESHOLD);
  };
  const handleTogglePwd = () => {
    setShowPassword(!showPassword);
  };
  const handleDeleteModal = (id, email) => {
    handleClickOpenDelete();
    setDeleteId(id);
    setUserEmail(email);
  };
  const handleDialogOpen = async () => {
    navigate(`/users/create`);
  };
  const handleResetModal = () => {
    handleClickOpenReset();
  };
  const handlePageChange = async (newPageNumber) => {
    setIsLoading(true);
    setPageNumber(newPageNumber);
    // await AllUsers();
    setIsLoading(false);
  };
  const handlePerPageChange = async (newPerPage, newPageNumber) => {
    setIsLoading(true);
    setDataPerPage(newPerPage);
    setPageNumber(newPageNumber);
    // await AllUsers();
    setIsLoading(false);
  };
  const columns = [
    {
      name: <b style={{ whiteSpace: "nowrap" }}>EMAIL</b>,
      selector: (row) => (
        <div className="email-text">{row.email ? row.email : "-"}</div>
      ),
      sortable: false,
    },
    {
      name: <b style={{ whiteSpace: "nowrap" }}>CREATION DATE</b>,
      selector: (row) =>
        row.creationDate ? moment(row.creationDate).format("DD-MM-YYYY") : "-",
      sortable: false,
    },
    {
      name: <b style={{ whiteSpace: "nowrap" }}>COMPANY NAME</b>,
      selector: (row) => (row.compnyName ? row.compnyName : "-"),
      sortable: false,
    },
    {
      name: <b style={{ whiteSpace: "nowrap" }}>EXPIRE DATE</b>,
      selector: (row) =>
        row.expiredDate ? moment(row.expiredDate).format("DD-MM-YYYY") : "-",
      sortable: false,
    },
    {
      name: <b style={{ whiteSpace: "nowrap", minWidth: "150px" }}>PHONE NO</b>,
      selector: (row) =>
        row.phoneNumber ? `+${row.countryCode}  ${row.phoneNumber}` : "-",
      sortable: false,
    },
    {
      name: <b style={{ whiteSpace: "nowrap" }}>ROLE</b>,
      selector: (row) => (row.userRole ? row.userRole : "-"),
      sortable: false,
    },
    {
      name: <b style={{ whiteSpace: "nowrap" }}>ACCESSES</b>,
      selector: (row) =>
        row.authorities?.slice(1, -1).split(", ").length
          ? row.authorities
              ?.slice(1, -1)
              .split(", ")
              .map((item) => {
                return <div>{item ? item : "-"}</div>;
              })
          : "-",
      sortable: false,
    },

    {
      name: <b>ACTION</b>,
      selector: (row) => (
        <div className="d-flex gap-2">
          {row.userRole == "ADMIN" ? (
            "-"
          ) : (
            <>
              <div
                onClick={() => navigate(`/users/edit/${row.id}`)}
                // onClick={() => handleDialogOpen(row.id)}
                className="btn btn-primary btn-sm"
              >
                <i className="fa fa-edit"></i>
              </div>
              <div
                onClick={() => handleDeleteModal(row.id, row.email)}
                className="btn btn-danger btn-sm"
              >
                <i className="fa fa-trash"></i>
              </div>
            </>
          )}

          {/* <div
                        onClick={() => handleResetModal(row.id)}
                        className="btn btn-warning btn-sm"
                    >
                        <i className="fa fa-recycle"></i>
                    </div> */}
        </div>
      ),
    },
  ];
  return (
    <>
      {openDelete && (
        <DeleteModal
          show={openDelete}
          onHide={handleClickCloseDelete}
          userDelete={userDelete}
          userEmail={userEmail}
          isLoading={isLoading}
        />
      )}
      {open && (
        <EditUserModal
          formik={formik}
          show={open}
          onHide={handleClickClose}
          showPassword={showPassword}
          handleTogglePwd={handleTogglePwd}
          isUpdate={isUpdate}
          isLoading={isLoading}
        />
      )}
      {openReset && (
        <ResetUserModal
          show={openReset}
          onHide={handleClickCloseReset}
          showPassword={showPassword}
          handleTogglePwd={handleTogglePwd}
        />
      )}
      <div className="page-header">
        <div>
          <h2 className="main-content-title tx-24 mg-b-5">USERS</h2>
        </div>
      </div>
      <div lg={12} className="w-100">
        <Card className="custom-card overflow-hidden">
          <Card.Body>
            <div className="responsive">
              <Card className="custom-card overflow-hidden">
                <div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      flexFlow: "wrap",
                      gap: "10px",
                      marginBottom: "14px",
                      marginTop: "4px",
                    }}
                  >
                    <input
                      className="p-2"
                      type="text"
                      placeholder="Search..."
                      value={searchText}
                      onChange={(e) => {
                        setSearchText(e.target.value);
                        debounceSearch(e.target.value);
                      }}
                      style={{
                        border: "1px solid #ced4da",
                        borderRadius: "4px",
                      }}
                    />
                    <div className="d-flex">
                      <div className="justify-content-center">
                        <Button
                          variant="primary"
                          type="button"
                          className="btn-icon-text"
                          onClick={() => handleDialogOpen("")}
                        >
                          <i className="fe fe-plus me-2"></i>Add User
                        </Button>
                      </div>
                    </div>
                  </div>
                  <DataTable
                    data={userData}
                    columns={columns}
                    noHeader
                    persistTableHead
                    center={true}
                    highlightOnHover
                    striped
                    pagination
                    paginationServer
                    paginationTotalRows={totalRows}
                    paginationPerPage={dataPerPage}
                    onChangeRowsPerPage={(page, totalRows) => {
                      handlePerPageChange(page, 1);
                    }}
                    onChangePage={handlePageChange}
                    progressPending={isLoading}
                    progressComponent={
                      <img src={Loader1} alt="Loader" height={70} />
                    }
                  />
                </div>
              </Card>
            </div>
          </Card.Body>
        </Card>
      </div>
    </>
  );
};
export default withLoader(UsersList);
