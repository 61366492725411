import React from "react";
import { Button, Modal } from "react-bootstrap";

const DeleteModal = (props) => {
  const { userEmail, userDelete, isLoading } = props;
  return (
    <>
      <Modal
        {...props}
        size="large"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete User</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <i className="icon ion-ios-checkmark-circle-outline tx-100 tx-danger  mg-t-20 "></i>

          <h4 className="tx-danger tx-semibold mg-b-20">Delete?</h4>
          <p className="mg-b-20 mg-x-20 ">
            Are you sure want to delete <b>{userEmail}</b> ?
          </p>

          <Button
            type="button"
            onClick={(e) => {
              e.preventDefault();
              userDelete();
            }}
            variant="danger"
          >
            {isLoading ? "Deleting..." : "Delete"}
          </Button>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default DeleteModal;
