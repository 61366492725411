import React from "react";
import UserForm from "./UserForm";

const CreateUser = () => {
  return (
    <div>
      <div className="page-header">
        <div>
          <h2 className="main-content-title tx-24 mg-b-5">Create User</h2>
        </div>
      </div>
      <UserForm />
    </div>
  );
};

export default CreateUser;
