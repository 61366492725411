import serverCall from "../../serverCall";

const createAbout = async (data) => {
  const response = await serverCall.post(`/aboutus/`, data);
  return response;
};

const getAbout = async () => {
  const response = await serverCall.get(`/aboutus/getabout`);
  return response;
};
const createContact = async (data) => {
  const response = await serverCall.post(`/contactdetail/`, data);
  return response;
};

const getContact = async () => {
  const response = await serverCall.get(`/contactdetail/admin`);
  return response;
};

const InfoService = {
  createAbout,
  getAbout,
  createContact,
  getContact,
};
export default InfoService;
