import serverCall from "../../serverCall";

const postImage = async (data) => {
  const response = await serverCall.post(`/auth/image`, data);
  return response;
};

const getImage = async () => {
  const response = await serverCall.get(`/auth/getimage`);
  return response;
};

const ImageService = {
  postImage,
  getImage,
};
export default ImageService;
