import { get } from "lodash";
import serverCall from "../../serverCall";

const signUp = async (userSignInRequest) => {
  const { email, password } = userSignInRequest;
  const response = await serverCall.post(
    `/auth/login/admin?email=${email}&password=${password}`
  );
  console.log("🚀  signUp  response:", response);
  if (response.data.apiresponse.data.token) {
    sessionStorage.setItem("token", response.data.apiresponse.data.token);
    sessionStorage.setItem("userId", response.data.apiresponse.data.id);
    return response;
  } else {
    return response;
  }
};
const AuthenticationService = {
  signUp,
};

export default AuthenticationService;
