import { faEye, faEyeSlash } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useFormik } from "formik";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Button, Form, Spinner, Row, Col, Container } from "react-bootstrap";
import PhoneInput from "react-phone-input-2";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import UserService from "../../services/user.service";
import { enqueueSnackbar } from "notistack";

const UserForm = (props) => {
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const { id } = useParams();
  let navigate = useNavigate();

  const handleTogglePwd = () => {
    setShowPassword(!showPassword);
  };

  const initialValues = {
    id: "",
    email: "",
    password: "",
    expiredDate: "",
    compnyName: "",
    contactPerson: "",
    phoneNumber: "",
    countryCode: "",
    authorities: [],
  };

  const validationSchema = Yup.object({
    password: isUpdate ? "" : Yup.string().required("Password is required!"),
    email: Yup.string().required("Email is required!"),
  });

  const handleFormSubmit = async (values, action) => {
    setIsLoading(true);
    const { authorities, email, password } = values;
    const newAuthorities = authorities?.filter((item) => item.trim() !== "");
    console.log(values, "valuesvalues");
    if (!values.id) {
      try {
        const response = await UserService.createUser({
          ...values,
          authorities: newAuthorities,
        });
        navigate("/users");
        action.resetForm();
        enqueueSnackbar("User created successfully.", {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });
      } catch (e) {
        console.log("e", e);
        enqueueSnackbar(e.response.data.apierror.message, {
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });
      } finally {
        setIsLoading(false);
      }
    } else {
      try {
        const response = await UserService.updateUser(values.id, {
          ...values,
          authorities: newAuthorities,
        });
        navigate("/users");
        action.resetForm();
        enqueueSnackbar("User updated successfully.", {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });
      } catch (e) {
        console.log("e", e);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: handleFormSubmit,
  });

  if (!formik.values.authorities) {
    formik.values.authorities = [];
  }
  useEffect(() => {
    getValues();
  }, [id]);

  const getValues = async () => {
    if (id) {
      setIsUpdate(true);
      const response = await UserService.getOne(id);
      console.log("getOne", response);
      const result = response.data.apiresponse.data;
      formik.setFieldValue("email", result.email);
      formik.setFieldValue("id", result.id);
      formik.setFieldValue("expiredDate", result.expiredDate);
      formik.setFieldValue("phoneNumber", result.phoneNumber);
      formik.setFieldValue("countryCode", result.countryCode);
      formik.setFieldValue("contactPerson", result.contactPerson);
      formik.setFieldValue("userRole", result.userRole);
      formik.setFieldValue("compnyName", result.compnyName);
      formik.setFieldValue(
        "authorities",
        result.authorities?.slice(1, -1).split(", ")
      );
    } else {
      setIsUpdate(false);
      formik.resetForm();
    }
  };

  const authoritiesOptions = [
    "Speech to text",
    "Video insights",
    "Fix Hidden Characters",
  ];

  return (
    <div className="">
      <Container fluid className="my-4 p-0">
        <Row className="justify-content-center">
          <Col xs={12} md={10} lg={8}>
            <div className="card p-4 p-md-5">
              <Form onSubmit={formik.handleSubmit}>
                <Form.Group as={Row} className="mb-3" controlId="email">
                  <Form.Label column sm={3} className="text-sm-start">
                    Email<span className="text-danger">*</span>
                  </Form.Label>
                  <Col sm={9}>
                    <Form.Control
                      type="email"
                      name="email"
                      placeholder="Enter your email"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      isInvalid={formik.touched.email && !!formik.errors.email}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.email}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-3" controlId="compnyName">
                  <Form.Label column sm={3} className="text-sm-start">
                    Company Name
                  </Form.Label>
                  <Col sm={9}>
                    <Form.Control
                      type="text"
                      name="compnyName"
                      placeholder="Enter your company name"
                      value={formik.values.compnyName}
                      onChange={formik.handleChange}
                      isInvalid={
                        formik.touched.compnyName && !!formik.errors.compnyName
                      }
                    />
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.compnyName}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-3" controlId="userRole">
                  <Form.Label column sm={3} className="text-sm-start">
                    Role
                  </Form.Label>
                  <Col sm={9}>
                    <Form.Control
                      as="select"
                      name="userRole"
                      value={formik.values.userRole}
                      onChange={formik.handleChange}
                    >
                      <option value="USER">User</option>
                      <option value="ADMIN">Admin</option>
                    </Form.Control>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-3" controlId="contactPerson">
                  <Form.Label column sm={3} className="text-sm-start">
                    Contact Person
                  </Form.Label>
                  <Col sm={9}>
                    <Form.Control
                      type="text"
                      name="contactPerson"
                      placeholder="Enter your contact person"
                      value={formik.values.contactPerson}
                      onChange={formik.handleChange}
                      isInvalid={
                        formik.touched.contactPerson &&
                        !!formik.errors.contactPerson
                      }
                    />
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.contactPerson}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-3" controlId="expiredDate">
                  <Form.Label column sm={3} className="text-sm-start">
                    Expired Date
                  </Form.Label>
                  <Col sm={9}>
                    <Form.Control
                      type="date"
                      name="expiredDate"
                      placeholder="Enter expired date"
                      value={moment(formik.values.expiredDate).format(
                        "YYYY-MM-DD"
                      )}
                      onChange={(e) => {
                        const selectedDate = e.target.value;
                        const timestamp = new Date(selectedDate).getTime();
                        formik.setFieldValue("expiredDate", timestamp);
                      }}
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-3" controlId="authorities">
                  <Form.Label column sm={3} className="text-sm-start">
                    Authorities
                  </Form.Label>
                  <Col sm={9}>
                    <div className="d-flex flex-wrap">
                      {authoritiesOptions.map((option, i) => (
                        <Form.Check
                          key={i}
                          type="checkbox"
                          id={`authority-${i}`}
                          label={option}
                          value={option}
                          checked={formik.values.authorities.includes(option)}
                          onChange={(e) => {
                            const isChecked = e.target.checked;
                            const value = e.target.value;
                            const authorities = formik.values.authorities;
                            if (isChecked) {
                              formik.setFieldValue("authorities", [
                                ...authorities,
                                value,
                              ]);
                            } else {
                              formik.setFieldValue(
                                "authorities",
                                authorities.filter((item) => item !== value)
                              );
                            }
                          }}
                          className="me-3"
                        />
                      ))}
                    </div>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-3" controlId="phoneNumber">
                  <Form.Label column sm={3} className="text-sm-start">
                    Phone Number
                  </Form.Label>
                  <Col sm={9}>
                    <PhoneInput
                      country={"in"}
                      value={
                        formik.values?.phoneNumber &&
                        formik.values?.countryCode &&
                        formik.values?.countryCode + formik.values?.phoneNumber
                      }
                      onChange={(value, country) => {
                        formik.setFieldValue(
                          "phoneNumber",
                          value.split(country?.dialCode)[1]
                        );
                        formik.setFieldValue("countryCode", country?.dialCode);
                      }}
                      inputClass="form-control"
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-3" controlId="password">
                  <Form.Label column sm={3} className="text-sm-start">
                    Password<span className="text-danger">*</span>
                  </Form.Label>
                  <Col sm={9} className="position-relative">
                    <Form.Control
                      type={showPassword ? "text" : "password"}
                      name="password"
                      placeholder="Enter your password"
                      value={formik.values.password}
                      onChange={formik.handleChange}
                      isInvalid={
                        formik.touched.password && !!formik.errors.password
                      }
                    />
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.password}
                    </Form.Control.Feedback>
                    <FontAwesomeIcon
                      icon={showPassword ? faEye : faEyeSlash}
                      onClick={handleTogglePwd}
                      style={{
                        cursor: "pointer",
                        position: "absolute",
                        right: 30,
                        top: "50%",
                        transform: "translateY(-50%)",
                      }}
                    />
                  </Col>
                </Form.Group>

                <div className="text-center">
                  <Button
                    className="px-5 mt-2"
                    style={{
                      marginRight: "10px",
                      backgroundColor: "transparent",
                      border: "1px solid #0386ff",
                      color: "#0386ff",
                      padding: "8px 16px",
                      borderRadius: "5px",
                      cursor: "pointer",
                      textDecoration: "none",
                    }}
                    onClick={() => {
                      navigate("/users");
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    style={{
                      marginRight: "10px",
                    }}
                    type="submit"
                    className="px-5 mt-2"
                    disabled={isLoading}
                  >
                    {isLoading ? (
                      <Spinner animation="border" role="status" size="sm" />
                    ) : isUpdate ? (
                      "Update"
                    ) : (
                      "Submit"
                    )}
                  </Button>
                </div>
              </Form>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default UserForm;
