import serverCall from "../../serverCall";
const GetAllUsers = async ({ pageNumber, pageSize, searchText }) => {
  const response = await serverCall.get(
    `/user/getAllUser?pagenumber=${pageNumber}&pagesize=${pageSize}&searchtext=${searchText}`
  );
  return response;
};
const createUser = async (data) => {
  // const { email, password } = data
  const response = await serverCall.post(`/user/register`, data);
  return response;
};
const deleteUser = async (id) => {
  const response = await serverCall.delete(`/user/${id}`);
  return response;
};
const getOne = async (id) => {
  const response = await serverCall.get(`/user/${id}`);
  return response;
};
const updateUser = async (id, data) => {
  const response = await serverCall.put(`/user/update/${id}`, data);
  return response;
};
const UserService = {
  GetAllUsers,
  createUser,
  deleteUser,
  getOne,
  updateUser,
};
export default UserService;
