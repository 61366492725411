import axios from "axios";
import config from "./config";
import { enqueueSnackbar } from "notistack";

const BASE_URL = config.baseApi;

const customAxios = axios.create({
  baseURL: BASE_URL,
});

const requestHandler = (request) => {
  if (sessionStorage.getItem("token")) {
    const token = sessionStorage.getItem("token");
    request.headers.Authorization = `${token}`;
  }
  // else if (sessionStorage.getItem('access-token')) {
  //     const accesstoken = JSON.parse(sessionStorage.getItem('access-token'));
  //     request.headers.Authorization = `Bearer ${accesstoken}`;
  // }
  return request;
};

const responseHandler = (response) => {
  if (response.status === 401 || response.status === 403) {
    window.location = "/subtitleAdmin/login";

    sessionStorage.removeItem("token");
  }
  return response;
};

const requestErrorHandler = (error) => {
  return Promise.reject(error);
};

const responseErrorHandler = (error) => {
  if (error.response) {
    if (error.response.status === 401) {
      sessionStorage.clear();
      window.location = "/subtitleAdmin/login";

      return Promise.reject(error);
    }
  }
  return Promise.reject(error);
};

customAxios.interceptors.request.use(
  (request) => requestHandler(request),
  (error) => requestErrorHandler(error)
);

customAxios.interceptors.response.use(
  (response) => responseHandler(response),
  responseErrorHandler
);

export default customAxios;
