let MENUITEMS = [];
MENUITEMS = [
  {
    Items: [
      {
        path: `/users`,
        icon: "ti-user",
        type: "link",
        active: false,
        selected: false,
        title: "Users List",
      },
      {
        path: `/home`,
        icon: "ti-home",
        type: "link",
        active: false,
        selected: false,
        title: "Home",
      },
      {
        path: `/speech-to-text`,
        icon: "ti-smallcap",
        type: "link",
        active: false,
        selected: false,
        title: "Speech To Text",
      },
      {
        path: `/video-insight`,
        icon: "ti-video-camera",
        type: "link",
        active: false,
        selected: false,
        title: "Video Insight",
      },
      {
        path: `/fix-hidden-characters`,
        icon: "ti-text",
        type: "link",
        active: false,
        selected: false,
        title: "Fix Hidden Characters",
      },
      {
        path: `/aboutus`,
        icon: "ti-info-alt",
        type: "link",
        active: false,
        selected: false,
        title: "About Us",
      },
      {
        path: `/contactus`,
        icon: "ti-email",
        type: "link",
        active: false,
        selected: false,
        title: "Contact Us",
      },
    ],
  },
];

export { MENUITEMS };
